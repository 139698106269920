import { XMarkIcon } from "@heroicons/react/20/solid";
import {
    DocumentArrowDownIcon,
    EnvelopeIcon,
    LinkIcon,
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import * as Common from "@sp-crm/core";
import { CommunityId, ContactId, FileId } from "@sp-crm/core";
import { CommunityCardTypes } from "components/community-search/community-card/community-card-types";
import { Activity } from "components/entity-activity/entity-activity";
import { SignatureRequestPanel } from "components/files/signature-request/signature-request-panel";
import { EntityPageHeader } from "components/layout";
import { EntityTab } from "components/layout/entity-tab";
import { getMarkerIconColorFromContractStatus } from "components/map/map-common";
import { MessageCompose } from "components/messages/message-compose";
import { SentMessages } from "components/messages/sent";
import { CreateReferralLinkPanel } from "components/references/create-referral-link-panel";
import {
    ActionsMenu,
    ActionsMenuAction,
    ActionsMenuSection,
} from "components/ui/actions-menu";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { SelectPro } from "components/ui/select-pro";
import { AdvancedSearchEntityType, FileEntityType } from "generated/graphql";
import * as React from "react";
import { ResponsiveMode } from "../../../store/reducers/responsive";
import util from "../../../util";
import { BylineHeader } from "../../byline/header";
import { CommunitiesComparePdfDialog } from "../../clients/show-client/community-comparison/community-pdf-dialog";
import { communityToListableCommunity } from "../../community-search/listable-community-utility";
import { AntiFeature, Feature } from "../../feature";
import { FilesControl } from "../../files";
import { Header } from "../../header";
import { NextgenStaticMap } from "../../map/nextgen-static";
import { Question } from "../../questions";
import Shared from "../../shared";
import { Col, Row } from "../../shared/grid";
import { SubnavItem } from "../../shared/subnav/subnav-types";
import { Tasks } from "../../tasks/child";
import { Rating } from "../../ui/rating";
import { CommunityAdvancedView } from "../advanced";
import { CommunityLicenses } from "../licenses";
import { BuiltInPriceFields } from "./built-in-price-fields";
import { CommunityInboundReferrals } from "./community-inbound-referrals";
import { CommunityOutboundReferrals } from "./community-outbound-referrals";
import { CommunityPlacementHistory } from "./community-placement-history";
import { InvoiceTab } from "./invoice-tab";
import { Thumbnail } from "./thumbnail";
import { ThumbnailUpload } from "./thumbnail-upload";

export type Subpage =
    | "details"
    | "attributes"
    | "records"
    | "history"
    | "tasks"
    | "outbound"
    | "inbound"
    | "invoices"
    | "files"
    | "emails"
    | "advanced"
    | "activity";

interface ShowCommunityProps {
    community: Common.ICommunity;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChanges: (changes: Record<string, any>) => void;
    onReloadData: () => void;
    questions: Common.IQuestion[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onAddPhotos?: (communityId: CommunityId, photos: File[]) => Promise<any>;
    onUpdatePhotos?: (
        communityId: CommunityId,
        photos: Partial<Common.IFile>[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ) => Promise<any>;
    onUpdatePhotoDescription?: (
        communityId: CommunityId,
        photoId: FileId,
        description: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ) => Promise<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onDeletePhoto?: (communityId: CommunityId, photoId: FileId) => Promise<any>;
    onUpdateAnswer: (answer: Common.IAnswer) => Promise<Common.IAnswer>;
    selectSubpage: (subpage: string) => void;
    closeSection: () => void;
    activeSubpage: Subpage;
    delete: () => Promise<void>;
    clients: Common.IClient[];
    responsiveMode: ResponsiveMode;
    now: Date;
    users: { [id: string]: Common.User };
    fileCount: number;
    contractsEnabled: boolean;
    newlyAddedContactPerson?: ContactId;
    showInvoicesTab: boolean;
    showAdvancedTab: boolean;
    faxEnabled: boolean;
    canUpdateCommunitySearch: boolean;
    signWiseEnabled: boolean;
    tasks: Common.ITask[];
    refetchTasks: () => void;
}

interface ShowCommunityState {
    contactAdded: boolean;
    isActionsMenuOpen: boolean;
    isContactActionsMenuOpen: boolean;
    isDeleteContactDialogOpen: boolean;
    inPdfWorkflow: boolean;
    newlyAddedContactPerson?: ContactId;
    previouslyAddedContactPerson?: ContactId;
    isComposing: boolean;
    showSignaturePanel: boolean;
    showCreateReferralLinkPanel: boolean;
}

export class ShowCommunity extends React.Component<
    ShowCommunityProps,
    ShowCommunityState
> {
    private communityId: CommunityId;

    constructor(props: ShowCommunityProps) {
        super(props);
        this.state = {
            contactAdded: false,
            isActionsMenuOpen: false,
            isDeleteContactDialogOpen: false,
            isContactActionsMenuOpen: false,
            newlyAddedContactPerson: null,
            previouslyAddedContactPerson: null,
            inPdfWorkflow: false,
            isComposing: false,
            showSignaturePanel: false,
            showCreateReferralLinkPanel: false,
        };

        this.getLastUpdatedLabel = this.getLastUpdatedLabel.bind(this);
        this.priceCommitHelper = this.priceCommitHelper.bind(this);
        this.clearRating = this.clearRating.bind(this);
        this.compose = this.compose.bind(this);
        this.closeCompose = this.closeCompose.bind(this);
        this.communityId = props.community.id;
        this.advancedTab = this.advancedTab.bind(this);
        this.activityTab = this.activityTab.bind(this);
        this.startDelete = this.startDelete.bind(this);
        this.startPdf = this.startPdf.bind(this);
        this.openSignaturePanel = this.openSignaturePanel.bind(this);
        this.closeSignaturePanel = this.closeSignaturePanel.bind(this);
        this.openCreateReferralLinkPanel = this.openCreateReferralLinkPanel.bind(this);
        this.closeCreateReferralLinkPanel = this.closeCreateReferralLinkPanel.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: ShowCommunityProps) {
        if (props.newlyAddedContactPerson != this.state.previouslyAddedContactPerson) {
            // only update state if we've actually received a *new* contact person
            this.setState({
                ...this.state,
                newlyAddedContactPerson: props.newlyAddedContactPerson,
            });
        }
    }

    private openSignaturePanel(): void {
        this.setState({
            showSignaturePanel: true,
        });
    }

    private closeSignaturePanel(): void {
        this.setState({
            showSignaturePanel: false,
        });
    }

    private openCreateReferralLinkPanel(): void {
        this.setState({
            showCreateReferralLinkPanel: true,
        });
    }

    private closeCreateReferralLinkPanel(): void {
        this.setState({
            showCreateReferralLinkPanel: false,
        });
    }

    private controlForQuestion(question: Common.IQuestion): JSX.Element {
        if (question.interaction == Common.InteractionType.none) {
            return null;
        }

        const answer =
            this.props.community.answers.find(x => x.questionId == question.id) ||
            new Common.Answer();

        return (
            <Question
                key={question.id}
                callback={this.props.onUpdateAnswer}
                question={question}
                answer={answer}
            />
        );
    }

    private hasQuestionsToDisplay(category: Common.QuestionCategories): boolean {
        const questions = this.props.questions || [];
        const questionsInCategory = questions.filter(
            q => q.category == category && q.interaction != Common.InteractionType.none,
        );
        const questionsEnabled = questionsInCategory.filter(q =>
            q.enabled(this.props.questions, this.props.community.answers),
        );
        return questionsEnabled.length > 0;
    }

    private questionSection(category: Common.QuestionCategories): JSX.Element {
        return this.questionSectionWithExtras(category, null);
    }

    private questionSectionWithExtras(
        category: Common.QuestionCategories,
        preJSX?: JSX.Element,
        postJSX?: JSX.Element,
    ): JSX.Element {
        if (preJSX == null && postJSX == null && !this.hasQuestionsToDisplay(category))
            return null;
        const questions = this.props.questions
            .filter(
                q =>
                    q.category == category &&
                    q.enabled(this.props.questions, this.props.community.answers),
            )
            .map(q => this.controlForQuestion(q));
        const details = Common.questionMetadata[category];

        return (
            <div className="input-form-block">
                <Header iconName={details.icon}>{details.communityHeading}</Header>
                <div className="space-y-2 md:space-y-3">
                    {preJSX}
                    {questions}
                    {postJSX}
                </div>
            </div>
        );
    }

    private customQuestionCheckboxes(): JSX.Element {
        return (
            <div className="custom-questions">
                {this.questionSection(Common.QuestionCategories.type)}
                {this.questionSection(Common.QuestionCategories.staff)}
                {this.questionSection(Common.QuestionCategories.services)}
                {this.questionSection(Common.QuestionCategories.amenity)}
            </div>
        );
    }

    private communityDetailsTab() {
        return (
            <EntityTab
                columns={[
                    Common.LayoutSectionParentKey.CommunityDetailsColumn1,
                    Common.LayoutSectionParentKey.CommunityDetailsColumn2,
                ]}
                entity={this.props.community}
                entityId={this.props.community.id}
                entityType="Community"
                answerMode={Common.QuestionSource.Legacy}
                onAnswerCommitted={(_entityId, answer) =>
                    this.props.onUpdateAnswer(answer)
                }
                onFieldChange={this.props.onFieldChange}
            />
        );
    }

    private priceCommitHelper(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        newValue: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        oldValue: any,
        priceProp: string,
        priceModifiedProp: string,
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const changes: Record<string, any> = {};
        // We don't want to update the lastModifiedPrice if the user simply
        // clicked in the box and back out.
        if (newValue || null != oldValue || null) {
            changes[priceProp] = newValue;
            changes[priceModifiedProp] = new Date().toISOString();
            this.props.onFieldChanges(changes);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private onCommitPricingNotes(newValue: any) {
        this.priceCommitHelper(
            newValue,
            this.props.community.priceDetails,
            "priceDetails",
            "pricingNotesLastModified",
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private getLastUpdatedLabel(prop: any): string {
        if (prop) {
            return "(last updated " + util.date.formatDateNoTime(prop) + ")";
        }

        return null;
    }

    private preFinanceFields(): JSX.Element {
        return (
            <BuiltInPriceFields
                onFieldChange={this.props.onFieldChange}
                getLastUpdatedLabel={this.getLastUpdatedLabel}
                priceCommitHelper={this.priceCommitHelper}
                community={this.props.community}
            />
        );
    }
    private postFinanceFields(): JSX.Element {
        return (
            <div className="input-form-section custom-question">
                <AutosavingInput
                    label="Other Pricing Notes"
                    initial={this.props.community.priceDetails}
                    onCommit={newVal => this.onCommitPricingNotes(newVal)}
                    multiLine={true}
                />
                <div className="text-sm">
                    {this.getLastUpdatedLabel(
                        this.props.community.pricingNotesLastModified,
                    )}
                </div>
            </div>
        );
    }

    private standardDetailFields(): JSX.Element {
        return (
            <>
                <div className="gender-dropdown">
                    <SelectPro
                        label="Gender restrictions?"
                        includePlaceholderOption
                        value={this.props.community.gender}
                        onChange={e => this.props.onFieldChange("gender", e.target.value)}
                        options={[
                            {
                                value: "both",
                                text: "Accepts male and female",
                            },
                            { value: "female", text: "Female only" },
                            { value: "male", text: "Male only" },
                        ]}
                    />
                </div>
                <AutosavingInput
                    label="Number of residents per caregiver"
                    initial={`${this.props.community.caregiverRatio}`}
                    onCommit={newVal =>
                        this.props.onFieldChange("caregiverRatio", newVal)
                    }
                    type="number"
                />
                <AutosavingInput
                    label="Total beds"
                    initial={`${this.props.community.unitsOrCapacity}`}
                    onCommit={newVal =>
                        this.props.onFieldChange("unitsOrCapacity", newVal)
                    }
                    type="number"
                />
            </>
        );
    }

    private communityAttributesTab() {
        return (
            <Row type="flex" className="collapsible">
                <Col className="full-width">
                    {this.questionSectionWithExtras(
                        Common.QuestionCategories.finance,
                        this.preFinanceFields(),
                        this.postFinanceFields(),
                    )}
                    {this.questionSection(Common.QuestionCategories.activity)}
                    {this.questionSectionWithExtras(
                        Common.QuestionCategories.otherDetails,
                        this.standardDetailFields(),
                    )}
                </Col>
                <Col className="full-width">{this.customQuestionCheckboxes()}</Col>
            </Row>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private updatePaymentType(v: any) {
        this.props.onFieldChange("paymentType", v);
    }

    private standardRecordFields(): JSX.Element {
        return (
            <div className="input-form-section">
                <Feature name="discreteLicenseRecords">
                    <CommunityLicenses communityId={this.communityId} />
                </Feature>
                <AntiFeature name="discreteLicenseRecords">
                    <SelectPro
                        label="State License Status"
                        includePlaceholderOption
                        placeholderStr="(unspecified)"
                        value={this.props.community.licenseStatus}
                        onChange={e =>
                            this.props.onFieldChange("licenseStatus", e.target.value)
                        }
                        options={[
                            { value: "active", text: Common.LicenseStatus.active },
                            { value: "closed", text: Common.LicenseStatus.closed },
                            {
                                value: "notApplicable",
                                text: Common.LicenseStatus.notApplicable,
                            },
                            {
                                value: "onProbation",
                                text: Common.LicenseStatus.onProbation,
                            },
                            { value: "pending", text: Common.LicenseStatus.pending },
                        ]}
                    />
                    <div className="licensed-since-date">
                        <Shared.DateInput
                            label="Community/Facility Licensed Date:"
                            initial={this.props.community.licensedSinceDate}
                            onCommit={(newVal: Date) =>
                                this.props.onFieldChange("licensedSinceDate", newVal)
                            }
                        />
                    </div>
                    <Feature name="discreteLicenseeField">
                        <div className="">
                            <AutosavingInput
                                label="Licensee"
                                initial={this.props.community.licensee}
                                onCommit={newVal =>
                                    this.props.onFieldChange("licensee", newVal)
                                }
                            />
                        </div>
                    </Feature>
                </AntiFeature>
            </div>
        );
    }

    private contractTab(): JSX.Element {
        return (
            <Row type="flex" className="collapsible">
                {this.contractPane()}
                <Col className="full-width">
                    <div className="input-form-section">
                        {this.questionSectionWithExtras(
                            Common.QuestionCategories.communityRecords,
                            this.standardRecordFields(),
                        )}
                    </div>
                </Col>
            </Row>
        );
    }

    private contractPane(): JSX.Element {
        if (!this.props.contractsEnabled) {
            return null;
        }

        return (
            <Col className="full-width">
                {this.questionSectionWithExtras(
                    Common.QuestionCategories.contract,
                    this.standardContractFields(),
                )}
            </Col>
        );
    }

    private standardContractFields(): JSX.Element {
        return (
            <>
                <div className="payment-type">
                    {/* TODO: FIX FORMATTING OF Payment Schedule and Contract Status*/}
                    <div>Payment Schedule:</div>
                    <div className="payment-schedule-row">
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    value="percentage"
                                    checked={
                                        this.props.community.paymentType === "percentage"
                                    }
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                    onChange={(v: any) =>
                                        this.updatePaymentType(v.target.value)
                                    }
                                />
                                &nbsp;By percentage
                            </label>
                        </div>
                        <AutosavingInput
                            disabled={this.props.community.paymentType !== "percentage"}
                            label=""
                            initial={this.props.community.paymentPercentage}
                            onCommit={newVal =>
                                this.props.onFieldChange("paymentPercentage", newVal)
                            }
                        />
                    </div>
                    <div className="payment-schedule-row">
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    value="fee"
                                    checked={this.props.community.paymentType === "fee"}
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                    onChange={(v: any) =>
                                        this.updatePaymentType(v.target.value)
                                    }
                                />
                                &nbsp;By fee
                            </label>
                        </div>
                        <AutosavingInput
                            disabled={this.props.community.paymentType !== "fee"}
                            label=""
                            initial={this.props.community.paymentFee}
                            onCommit={newVal =>
                                this.props.onFieldChange("paymentFee", newVal)
                            }
                        />
                    </div>
                </div>
                <SelectPro
                    label={"Contract Status"}
                    includePlaceholderOption
                    placeholderStr="(unspecified)"
                    value={this.props.community.contractStatus}
                    onChange={e =>
                        this.props.onFieldChange("contractStatus", e.target.value)
                    }
                    options={[
                        { value: "pending", text: "Pending" },
                        { value: "active", text: "Active" },
                        { value: "oneOff", text: "One off / Honor Contract" },
                        { value: "none", text: "No contract" },
                        { value: "terminated", text: "Terminated" },
                    ]}
                />

                <div className="contract-issued-date">
                    <Shared.DateInput
                        label="Contract Valid Since"
                        initial={this.props.community.contractIssuedDate}
                        onCommit={(newVal: Date) =>
                            this.props.onFieldChange("contractIssuedDate", newVal)
                        }
                    />
                </div>
                <div className="contract-expires-date">
                    <Shared.DateInput
                        label="Contract Expires"
                        initial={this.props.community.contractExpiresDate}
                        onCommit={(newVal: Date) =>
                            this.props.onFieldChange("contractExpiresDate", newVal)
                        }
                    />
                </div>
                <AutosavingInput
                    label="Contract Notes / Terms"
                    initial={this.props.community.contractNotes}
                    onCommit={newVal => this.props.onFieldChange("contractNotes", newVal)}
                    multiLine={true}
                />
                <Checkbox
                    disabled={!this.props.canUpdateCommunitySearch}
                    onChange={newVal =>
                        this.props.onFieldChange(
                            "showInSearchResults",
                            newVal.target.checked,
                        )
                    }
                    checked={this.props.community.showInSearchResults}
                    label="Allow clients to be matched with this community"
                />
            </>
        );
    }

    private placementHistoryTab(): JSX.Element {
        return (
            <CommunityPlacementHistory
                clients={this.props.clients}
                responsiveMode={this.props.responsiveMode}
                users={this.props.users}
            />
        );
    }

    private outboundReferralsTab(): JSX.Element {
        return (
            <section className="list-clients">
                <CommunityOutboundReferrals community={this.props.community} />
            </section>
        );
    }

    private inboundReferralsTab(): JSX.Element {
        return (
            <section className="list-clients">
                <CommunityInboundReferrals community={this.props.community} />
            </section>
        );
    }

    private invoicesTab(): JSX.Element {
        return (
            <section className="list-clients">
                <InvoiceTab community={this.props.community} />
            </section>
        );
    }

    private tasksTab(): JSX.Element {
        return (
            <Row type="flex" className="collapsible">
                <Col className="more-than-half-width">
                    <Tasks
                        parentId={this.props.community.id}
                        tasks={this.props.tasks}
                        refetch={this.props.refetchTasks}
                    />
                </Col>
            </Row>
        );
    }

    private communityName(): string {
        return this.props.community.name || "(no name)";
    }

    private startPdf() {
        this.setState({ inPdfWorkflow: true });
    }

    private compose(): void {
        this.setState({
            isComposing: true,
            isActionsMenuOpen: false,
        });
    }

    private closeCompose(): void {
        this.setState({
            isComposing: false,
        });
    }

    private headerBar() {
        return (
            <EntityPageHeader>
                <div className="page-title lg:flex lg:items-center lg:space-x-4">
                    <h2>{this.communityName()}</h2>
                    <div className="mb-2">
                        <CommunityCardTypes
                            community={communityToListableCommunity(this.props.community)}
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <div>
                        <BylineHeader entity={this.props.community} />
                    </div>
                    <div>
                        <ActionsMenu>
                            <ActionsMenuSection>
                                <ActionsMenuAction
                                    Icon={EnvelopeIcon}
                                    onClick={this.compose}>
                                    Send email
                                </ActionsMenuAction>
                                {this.props.signWiseEnabled ? (
                                    <ActionsMenuAction
                                        Icon={PencilSquareIcon}
                                        onClick={this.openSignaturePanel}>
                                        Request signature
                                    </ActionsMenuAction>
                                ) : null}
                                <ActionsMenuAction
                                    Icon={DocumentArrowDownIcon}
                                    onClick={this.startPdf}>
                                    Save community PDF
                                </ActionsMenuAction>
                                <ActionsMenuAction
                                    Icon={LinkIcon}
                                    onClick={this.openCreateReferralLinkPanel}>
                                    Share referral link
                                </ActionsMenuAction>
                            </ActionsMenuSection>
                            <ActionsMenuSection>
                                <ActionsMenuAction
                                    Icon={TrashIcon}
                                    onClick={this.startDelete}>
                                    Delete
                                </ActionsMenuAction>
                            </ActionsMenuSection>
                        </ActionsMenu>
                    </div>
                </div>
            </EntityPageHeader>
        );
    }

    private async clearRating() {
        const result = await fancyConfirm(
            "Clear rating?",
            "Would you like to clear the rating for this community?",
            "Yes, clear",
            "Cancel",
        );
        if (result) {
            this.props.onFieldChange("rating", null);
        }
    }

    private actionBar() {
        return (
            <div className="action-bar flex items-begin">
                <div className="flex-column widget community-image-and-upload space-y-1">
                    <Thumbnail community={this.props.community} size="128" />
                    <ThumbnailUpload
                        communityId={this.props.community.id}
                        reloadCommunity={this.props.onReloadData}
                    />
                </div>

                <div className="flex-column widget notes-form">
                    <div className="mb-2">
                        <label htmlFor="edit-community-summary-notes">
                            <h3>Summary Notes</h3>
                        </label>
                        <AutosavingInput
                            multiLine={true}
                            rows={5}
                            initial={this.props.community.summary}
                            onCommit={newVal =>
                                this.props.onFieldChange("summary", newVal)
                            }
                        />
                    </div>
                    <div className="flex items-center space-x-2">
                        <h3 className="block">Rating</h3>
                        <span className="block">
                            <Rating
                                rating={this.props.community.rating ?? 0}
                                onChange={newRating => {
                                    this.props.onFieldChange("rating", newRating);
                                }}
                            />
                        </span>
                        {this.props.community.rating &&
                        this.props.community.rating > 0 ? (
                            <span className="flex items-center space-x-1">
                                {this.props.community.rating === 1 ? (
                                    <span className="block">
                                        {this.props.community.rating}&nbsp;star
                                    </span>
                                ) : (
                                    <span className="block">
                                        {this.props.community.rating}&nbsp;stars
                                    </span>
                                )}

                                <span className="block">
                                    <a href="#" onClick={this.clearRating}>
                                        <XMarkIcon className="w-3 h-3 mt-1 ml-0.5 p-0.5 rounded-full text-gray-600 bg-gray-300 hover:bg-gray-400 hover:text-gray-900" />
                                    </a>
                                </span>
                            </span>
                        ) : (
                            <span className="block">Unrated</span>
                        )}
                    </div>
                </div>

                <div className="flex-column widget map">
                    <NextgenStaticMap
                        entityId={this.props.community.id}
                        location={this.props.community.location}
                        iconType={getMarkerIconColorFromContractStatus(
                            this.props.community.contractStatus,
                        )}
                    />
                </div>
            </div>
        );
    }

    private async startDelete(): Promise<void> {
        const result = await fancyConfirm(
            `Delete ${this.communityName()}?`,
            `Really delete ${this.communityName()}?`,
            "Yes, delete",
            "Cancel",
            { extraConfirm: true },
        );
        if (result) {
            this.props.delete();
        }
    }

    private composeWindow(): JSX.Element | null {
        if (!this.state.isComposing) {
            return null;
        }
        return (
            <MessageCompose
                entityType={AdvancedSearchEntityType.Community}
                entityId={this.props.community.id}
                onDismiss={this.closeCompose}
            />
        );
    }

    private dialogs(): JSX.Element {
        return (
            <div>
                {this.composeWindow()}
                {this.state.inPdfWorkflow && (
                    <CommunitiesComparePdfDialog
                        action={`/api/communities/${this.props.community.id}/pdf`}
                        onDismiss={() =>
                            this.setState({
                                inPdfWorkflow: false,
                            })
                        }
                    />
                )}
                <SignatureRequestPanel
                    fileEntityType={FileEntityType.Community}
                    entityId={this.props.community.id}
                    isOpen={this.state.showSignaturePanel}
                    onDismiss={this.closeSignaturePanel}
                />
                {this.state.showCreateReferralLinkPanel ? (
                    <CreateReferralLinkPanel
                        referenceCommunityId={this.props.community.id}
                        onDismiss={this.closeCreateReferralLinkPanel}
                    />
                ) : null}
            </div>
        );
    }

    private filesTab() {
        return (
            <FilesControl
                entityId={this.props.community.id}
                entityType={FileEntityType.Community}
            />
        );
    }

    private advancedTab(): JSX.Element {
        return <CommunityAdvancedView communityId={this.communityId} />;
    }

    private activityTab(): JSX.Element {
        return <Activity entityId={this.communityId} entityType="Community" />;
    }

    private emailsSentTab(): JSX.Element {
        return (
            <SentMessages
                entityId={this.props.community.id}
                showClient={true}
                showCommunity={false}
            />
        );
    }

    render() {
        const activeTasks = this.props.tasks.filter(t => t.active()) || [];
        const anyOverdue = activeTasks.some(t => t.overdue()) || false;
        const subpages: SubnavItem[] = [
            {
                href: `/communities/show/${this.props.community.id}/details`,
                subpage: "details",
                linkText: "Details",
                render: this.communityDetailsTab.bind(this),
            },
            {
                href: `/communities/show/${this.props.community.id}/attributes`,
                subpage: "attributes",
                linkText: "Attributes",
                render: this.communityAttributesTab.bind(this),
            },
            {
                href: `/communities/show/${this.props.community.id}/records`,
                subpage: "records",
                linkText: this.props.contractsEnabled
                    ? "Records and Contracts"
                    : "Records",
                render: this.contractTab.bind(this),
            },
            {
                href: `/communities/show/${this.props.community.id}/history`,
                subpage: "history",
                linkText: "Placements",
                render: this.placementHistoryTab.bind(this),
            },
            {
                href: `/communities/show/${this.props.community.id}/outbound`,
                subpage: "outbound",
                linkText: "Outbound Referrals",
                render: this.outboundReferralsTab.bind(this),
            },
            {
                href: `/communities/show/${this.props.community.id}/inbound`,
                subpage: "inbound",
                linkText: "Inbound Referrals",
                render: this.inboundReferralsTab.bind(this),
            },
        ];
        if (this.props.showInvoicesTab) {
            subpages.push({
                href: `/communities/show/${this.props.community.id}/invoices`,
                subpage: "invoices",
                linkText: "Invoices",
                render: this.invoicesTab.bind(this),
            });
        }
        subpages.push({
            href: `/communities/show/${this.props.community.id}/emails`,
            subpage: "emails",
            linkText: this.props.faxEnabled ? "Messages" : "Emails Sent",
            render: this.emailsSentTab.bind(this),
        });
        subpages.push({
            href: `/communities/show/${this.props.community.id}/tasks`,
            subpage: "tasks",
            linkText: "Tasks",
            render: this.tasksTab.bind(this),
            badgeCount: activeTasks.length || undefined,
            badgeType: anyOverdue ? "error" : "info",
        });
        subpages.push({
            href: `/communities/show/${this.props.community.id}/files`,
            subpage: "files",
            linkText: `Files`,
            badgeCount: this.props.fileCount,
            render: this.filesTab.bind(this),
        });
        subpages.push({
            href: `/communities/show/${this.props.community.id}/activity`,
            subpage: "activity",
            linkText: "Activity",
            render: this.activityTab,
        });
        if (this.props.showAdvancedTab) {
            subpages.push({
                href: `/communities/show/${this.props.community.id}/advanced`,
                subpage: "advanced",
                linkText: "Advanced",
                render: this.advancedTab,
            });
        }

        const grayBg =
            !this.props.activeSubpage ||
            this.props.activeSubpage === "details" ||
            this.props.activeSubpage === "attributes" ||
            this.props.activeSubpage === "records" ||
            this.props.activeSubpage === "invoices" ||
            this.props.activeSubpage === "emails" ||
            this.props.activeSubpage === "advanced" ||
            this.props.activeSubpage === "activity" ||
            this.props.activeSubpage === "history";

        return (
            <div>
                <div className="row">
                    <div className="component-wrapper col">
                        {this.headerBar()}
                        {this.dialogs()}
                        {this.actionBar()}
                        <div className="row">
                            <div
                                className={`col main-body full-width ${
                                    grayBg ? "bg-gray-100 min-h-screen" : ""
                                }`}>
                                <Shared.Nav.Subnav
                                    navigate={this.props.selectSubpage}
                                    selectedSubpage={this.props.activeSubpage}
                                    subpages={subpages}
                                    closeSection={this.props.closeSection}
                                    defaultSubpage="details"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
