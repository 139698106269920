import {
    ActionType,
    displayName,
    File,
    FileEntityId,
    FileId,
    formatDate,
    formatNumber,
    genInternalId,
    globalFilesEntityId,
    IFile,
    isFilePreviewable,
    isPdf,
} from "@sp-crm/core";
import { AccessControlEditor } from "components/access-control/access-control-editor";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { DeleteButton } from "components/ui/action-button";
import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { TextArea } from "components/ui/textarea";
import { Formik, FormikErrors } from "formik";
import {
    FileEntityType,
    GetFileQuery,
    useDeleteFileMutation,
    useGetFileQuery,
    useGetSignatureRequestsForEntityQuery,
    useUpdateFileMutation,
} from "generated/graphql";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIsAllowed, useTenantSettings } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { pluralize } from "util/pluralize";
import { truncate } from "util/text";
import { LoadingState } from "../../constants/loading";
import * as actions from "../../store/actions/files";
import { FileIcon, SignableDocumentIcon } from "./file-icon";
import { FilePreview } from "./file-preview";
import { SignatureRequestList } from "./signature-request/signature-request-list";
import { SignatureRequestPanel } from "./signature-request/signature-request-panel";
import { Upload } from "./upload";

interface FilesControlProps {
    entityId: FileEntityId;
    entityType?: FileEntityType;
}

interface DisplayableFile {
    fileId: FileId;
    createdAt: Date;
    description: string;
    originalFilename: string;
    sortableFilename: string;
    sortableUsername: string;
    displayUsername: string;
    mimeType: string;
    hasSignatureTemplate: boolean;
}

type FileAccessControl = GetFileQuery["getFile"]["accessControls"][0];

const initialSort = [
    {
        id: "sortableFilename",
        desc: false,
    },
];

export const FilesControl: React.FC<FilesControlProps> = props => {
    const { entityId, entityType } = props;
    const files = useSelector((state: ApplicationState) => state.files.files[entityId]);
    const fileSearch = useSelector(
        (state: ApplicationState) => state.filesDashboard[entityId] || { query: "" },
    );
    const loadingState = useSelector(
        (state: ApplicationState) =>
            state.files.loading[entityId] || LoadingState.NOTSTARTED,
    );
    const users = useSelector((state: ApplicationState) => state.users.users);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
    const [selectedFile, setSelectedFile] = React.useState<DisplayableFile | null>(null);
    const [showSignWisePanel, setShowSignWisePanel] = React.useState(false);
    const onUploadComplete = React.useCallback(() => {
        actions.loadFilesAction(entityId, dispatch);
        setErrorMessage(null);
        setSuccessMessage("Upload complete");
        setTimeout(() => {
            setSuccessMessage(null);
        }, 10 * 1000);
    }, [entityId, dispatch]);
    const onUploadError = React.useCallback((message: string) => {
        setSuccessMessage(null);
        setErrorMessage(`Error uploading file: ${message}`);
    }, []);
    React.useEffect(() => {
        if (loadingState !== LoadingState.DONE) {
            actions.loadFilesAction(entityId, dispatch);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const closePanel = React.useCallback(() => {
        setSelectedFile(null);
    }, []);

    const tenantSettings = useTenantSettings();

    const signWiseEnabled = tenantSettings.enableSignWise && !!entityType;

    const handleNewSignWise = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setShowSignWisePanel(true);
        },
        [setShowSignWisePanel],
    );

    const handleCloseSignWise = React.useCallback(() => {
        setShowSignWisePanel(false);
    }, [setShowSignWisePanel]);

    const signWiseRequestsQuery = useGetSignatureRequestsForEntityQuery(
        { entityId },
        { enabled: signWiseEnabled, keepPreviousData: true },
    );

    const columns: CrmTableProps<DisplayableFile>["columns"] = React.useMemo(
        () => [
            {
                key: "sortableFilename",
                header: "Name",
                renderCell: ({ record }) => (
                    <a
                        href="#"
                        onClick={(
                            e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
                        ) => {
                            e.preventDefault();
                            setSelectedFile(record);
                        }}
                        rel="noreferrer"
                        className={`${defaultLinkStyle} flex items-center space-x-2`}>
                        {record.hasSignatureTemplate ? (
                            <SignableDocumentIcon />
                        ) : (
                            <FileIcon mimeType={record.mimeType} />
                        )}
                        <div>{record.originalFilename ?? "(untitled)"}</div>
                    </a>
                ),
            },
            {
                key: "description",
                header: "Description",
            },
            {
                key: "createdAt",
                header: "Uploaded",
                renderCell: ({ record }) => <span>{formatDate(record.createdAt)}</span>,
            },
            {
                key: "sortableUsername",
                header: "Uploaded by",
                renderCell: ({ record }) => <span>{record.displayUsername}</span>,
            },
        ],
        [setSelectedFile],
    );

    const displayableFiles = React.useMemo(() => {
        return (files || [])
            .filter(f => !f.deleted)
            .filter(
                f =>
                    fileSearch.query.length === 0 ||
                    (f.originalFilename.toLocaleLowerCase() ?? "").includes(
                        (fileSearch.query ?? "").toLocaleLowerCase(),
                    ),
            )
            .map(f => ({
                fileId: f.id,
                createdAt: f.createdAt,
                originalFilename: f.originalFilename,
                sortableFilename: f.originalFilename.toLocaleLowerCase(),
                description: truncate(f.description, 60),
                placeholder: {},
                mimeType: f.mimeType,
                sortableUsername: displayName(users[f.createdBy]).toLocaleLowerCase(),
                displayUsername: displayName(users[f.createdBy]),
                hasSignatureTemplate: f.hasSignatureTemplate,
            }));
    }, [files, users, fileSearch.query]);

    if (
        loadingState === LoadingState.NOTSTARTED ||
        loadingState === LoadingState.LOADING
    ) {
        return <Spinner />;
    }
    if (loadingState === LoadingState.ERROR) {
        return (
            <InlineBanner type="error">
                Sorry, there was an error loading the files. Please reload.
            </InlineBanner>
        );
    }
    if (loadingState === LoadingState.DONE) {
        return (
            <div className="space-y-4">
                {signWiseEnabled ? (
                    <p>To add a file, upload a file or start a new SignWise request</p>
                ) : null}
                <div className="flex space-x-2 items-center">
                    <Upload
                        onComplete={onUploadComplete}
                        onError={onUploadError}
                        endpoint={`/api/files/${entityId}`}
                    />
                    {signWiseEnabled ? (
                        <div>
                            <SecondaryButton onClick={handleNewSignWise}>
                                New SignWise request
                            </SecondaryButton>
                        </div>
                    ) : null}
                </div>
                <div className="space-y-1">
                    {errorMessage ? (
                        <InlineBanner type="error">{errorMessage}</InlineBanner>
                    ) : null}
                    {successMessage ? (
                        <InlineBanner type="success">{successMessage}</InlineBanner>
                    ) : null}
                </div>
                {signWiseRequestsQuery.data?.getSignatureRequestsForEntity?.length > 0 ? (
                    <div>
                        <p className="text-lg">SignWise requests</p>
                        <SignatureRequestList
                            entityId={entityId}
                            refetchSignatures={signWiseRequestsQuery.refetch}
                            signatureRequests={
                                signWiseRequestsQuery.data.getSignatureRequestsForEntity
                            }
                        />
                    </div>
                ) : null}
                <div>
                    {signWiseEnabled ? <p className="text-lg">All files</p> : null}
                    {displayableFiles.length === 0 && !fileSearch.query ? (
                        <div className="space-y-1 mt-4">
                            <div className="text-gray-500 flex items-center space-x-1">
                                <FileIcon mimeType="pdf" />
                                <div>No files uploaded yet.</div>
                            </div>
                            <div className="text-sm text-gray-300">
                                Click <em>Upload file</em> above to get started.
                            </div>
                        </div>
                    ) : (
                        <CrmTable
                            initialSort={initialSort}
                            columns={columns}
                            data={displayableFiles}
                        />
                    )}
                </div>
                <Panel
                    onDismiss={closePanel}
                    type={
                        isFilePreviewable(selectedFile)
                            ? PanelType.extraLarge
                            : PanelType.large
                    }
                    isOpen={!!selectedFile}
                    headerText="">
                    {selectedFile ? (
                        <FileEdit
                            isGlobalFile={entityId === globalFilesEntityId}
                            close={closePanel}
                            fileId={selectedFile.fileId}
                        />
                    ) : null}
                </Panel>
                {signWiseEnabled ? (
                    <SignatureRequestPanel
                        entityId={entityId}
                        fileEntityType={entityType}
                        isOpen={showSignWisePanel}
                        onDismiss={handleCloseSignWise}
                    />
                ) : null}
            </div>
        );
    }
    throw "Unknown rendering state.";
};

interface FileEditProps {
    fileId: FileId;
    close: () => void;
    isGlobalFile: boolean;
}

interface FileEditFormValues {
    description: string;
    originalFilename: string;
    shareWithClient: boolean;
    shareWithCommunity: boolean;
    shareWithReferral: boolean;
    accessControls: FileAccessControl[];
}

export const FileEdit: React.FC<FileEditProps> = props => {
    const { fileId, close, isGlobalFile } = props;
    const dispatch = useDispatch();
    const updateMutation = useUpdateFileMutation();
    const deleteMutation = useDeleteFileMutation();
    const fileQuery = useGetFileQuery({ fileId });
    const [deleteError, setDeleteError] = React.useState<string | null>(null);
    const onDelete = React.useCallback(async () => {
        try {
            setDeleteError(null);
            await deleteMutation.mutateAsync({ fileId });
            const entityId: FileEntityId = fileQuery.data?.getFile?.entityId ?? null;
            dispatch(actions.deleteFilePayload(fileId, entityId));
            close();
        } catch (e) {
            setDeleteError(`Could not delete this file: ${e.message}`);
        }
    }, [dispatch, deleteMutation, fileQuery, close, fileId]);
    const canModifyAcls = useIsAllowed(ActionType.ModifyAcls);
    const tenantSettings = useTenantSettings();
    const signWiseEnabled = tenantSettings.enableSignWise && isGlobalFile;
    const isFilePdf = isPdf(fileQuery.data?.getFile?.mimeType);

    const onSubmit = React.useCallback(
        async (values: FileEditFormValues) => {
            const result = await updateMutation.mutateAsync({
                fileId,
                details: {
                    description: values.description,
                    originalFilename: values.originalFilename,
                    shareWithClient: values.shareWithClient,
                    shareWithCommunity: values.shareWithCommunity,
                    shareWithReferral: values.shareWithReferral,
                },
                accessControls: canModifyAcls ? values.accessControls : null,
            });
            fileQuery.refetch();
            dispatch(
                actions.updateFilePayload(
                    genInternalId(),
                    File.load(result.updateFile as unknown as IFile),
                ),
            );
            close();
        },
        [fileQuery, dispatch, updateMutation, close, fileId, canModifyAcls],
    );
    const validateFormInput = (
        values: FileEditFormValues,
    ): FormikErrors<FileEditFormValues> => {
        const errors: FormikErrors<FileEditFormValues> = {};
        if (!values.originalFilename || values.originalFilename.length === 0) {
            errors.originalFilename = "Filename is required.";
        }
        if (values.originalFilename.length > 255) {
            errors.originalFilename = "Filename must be 255 characters or less.";
        }
        return errors;
    };
    return (
        <QueryRenderer name="File edit" query={fileQuery}>
            {data => {
                const initialValues = {
                    description: data.getFile.description,
                    originalFilename: data.getFile.originalFilename,
                    shareWithClient: data.getFile.shareWithClient,
                    shareWithCommunity: data.getFile.shareWithCommunity,
                    shareWithReferral: data.getFile.shareWithReferral,
                    accessControls: data.getFile.accessControls,
                };
                const outstandingSignatureRequestsCount =
                    data.getFile.outstandingSignatureRequestsCount;
                const requestPluralized = pluralize(
                    outstandingSignatureRequestsCount,
                    "request",
                );
                return (
                    <Formik
                        onSubmit={onSubmit}
                        validate={validateFormInput}
                        initialValues={initialValues}>
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            touched,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="space-y-4">
                                    <div className="flex items-center justify-between space-x-2">
                                        <a
                                            href={`/api/files/download/${fileId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={`${defaultLinkStyle} flex items-center space-x-2`}>
                                            <FileIcon mimeType={data.getFile.mimeType} />
                                            <div>{data.getFile.originalFilename}</div>
                                        </a>
                                        <DeleteButton
                                            backgroundColor="bg-white"
                                            onClick={onDelete}
                                            confirm={
                                                outstandingSignatureRequestsCount > 0
                                                    ? {
                                                          title: "Delete SignWise file?",
                                                          message: `${
                                                              data.getFile
                                                                  .originalFilename
                                                          } has ${formatNumber(
                                                              outstandingSignatureRequestsCount,
                                                          )} outstanding SignWise ${requestPluralized}. Deleting this file will cancel the ${requestPluralized}. Are you sure you want to proceed?`,
                                                      }
                                                    : {
                                                          title: "Delete file?",
                                                          message: `Really delete ${data.getFile.originalFilename}?`,
                                                      }
                                            }
                                        />
                                    </div>
                                    {deleteError ? (
                                        <InlineBanner type="error">
                                            {deleteError}
                                        </InlineBanner>
                                    ) : null}
                                    <div>
                                        <Input
                                            label="Name"
                                            name="originalFilename"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.originalFilename}
                                        />
                                        {touched.originalFilename &&
                                        errors.originalFilename ? (
                                            <div className="text-red-700 font-bold mt-1">
                                                {errors.originalFilename}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div>
                                        <TextArea
                                            label="Description"
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            rows={6}
                                            value={values.description}
                                        />
                                    </div>

                                    <div>
                                        <p>Record visibility</p>
                                        <Checkbox
                                            label="Can be shared with clients"
                                            name="shareWithClient"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.shareWithClient}
                                        />
                                        <Checkbox
                                            label="Can be shared with communities"
                                            name="shareWithCommunity"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.shareWithCommunity}
                                        />
                                        <Checkbox
                                            label="Can be shared with referrals"
                                            name="shareWithReferral"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.shareWithReferral}
                                        />
                                    </div>
                                    <AccessControlEditor
                                        label="User visibility"
                                        accessControls={values.accessControls}
                                        onChange={ac =>
                                            setFieldValue("accessControls", ac)
                                        }
                                    />
                                    <div className="mt-1">
                                        <a
                                            href={`/api/files/download/${fileId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={`${defaultLinkStyle} flex items-center space-x-2`}>
                                            <FileIcon mimeType={data.getFile.mimeType} />
                                            <div>Download</div>
                                        </a>
                                        {signWiseEnabled && isFilePdf ? (
                                            <Link
                                                className={`${defaultLinkStyle} flex items-center space-x-2`}
                                                to={`/files/${fileId}/template`}>
                                                <SignableDocumentIcon />
                                                <div>Signature template</div>
                                            </Link>
                                        ) : null}
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <SecondaryButton type="button" onClick={close}>
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit">Save</PrimaryButton>
                                    </div>
                                </div>
                                <FilePreview file={data.getFile} />
                            </form>
                        )}
                    </Formik>
                );
            }}
        </QueryRenderer>
    );
};
