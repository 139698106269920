import { UnauthenticatedError } from "@sp-crm/core";
import React, { FunctionComponent } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { clientLogout } from "store/actions";

interface AppProps {
    children: React.ReactNode;
}

export const StoreAwareQueryClientProvider: FunctionComponent<AppProps> = ({
    children,
}) => {
    const dispatch = useDispatch();

    const queryClient = React.useMemo(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    onError: error => {
                        if (error instanceof UnauthenticatedError) {
                            clientLogout(dispatch);
                        }
                    },
                }),
                mutationCache: new MutationCache({
                    onError: error => {
                        if (error instanceof UnauthenticatedError) {
                            clientLogout(dispatch);
                        }
                    },
                }),
            }),
        [dispatch],
    );

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
